export const urlify = (text: string): string => {
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, function (url) {
		// let urlm = url.replaceAll('</br>', ' ');
		// console.log(url);
		// console.log(urlm);
		return '<a class="mention" target="_blank" href="' + url + '">' + url + '</a>';
	});
};
export const hasUrl = (content: string): boolean => {
	const regexIsUrl = /(https?:\/\/[^\s]+)/g;

	return regexIsUrl.test(content);
};
