<template>
  <div class="outter-full-fixed" :class="classList">
    <div class="wrapper customize-loader-page">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <!-- <span>Loading</span> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classList: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.outter-full-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 444;
  background-color: rgb(169, 169, 169);
  .customize-loader-page {
    width: 200px;
    height: 60px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .circle {
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 50%;
      background-color: #fff;
      left: 15%;
      transform-origin: 50%;
      animation: circle 0.5s alternate infinite ease;
    }

    @keyframes circle {
      0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
        background-color: #fff;
      }
      40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
        background-color: rgb(243, 236, 178);
      }
      100% {
        top: 0%;
      }
    }
    .circle:nth-child(2) {
      left: 45%;
      animation-delay: 0.2s;
    }
    .circle:nth-child(3) {
      left: auto;
      right: 15%;
      animation-delay: 0.3s;
    }
    .shadow {
      width: 20px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 62px;
      transform-origin: 50%;
      z-index: -1;
      left: 15%;
      filter: blur(1px);
      animation: shadow 0.5s alternate infinite ease;
    }

    @keyframes shadow {
      0% {
        transform: scaleX(1.5);
      }
      40% {
        transform: scaleX(1);
        opacity: 0.7;
      }
      100% {
        transform: scaleX(0.2);
        opacity: 0.4;
      }
    }
    .shadow:nth-child(4) {
      left: 45%;
      animation-delay: 0.2s;
    }
    .shadow:nth-child(5) {
      left: auto;
      right: 15%;
      animation-delay: 0.3s;
    }
    .wrapper span {
      position: absolute;
      top: 75px;
      font-family: 'Lato';
      font-size: 20px;
      letter-spacing: 12px;
      color: #fff;
      left: 15%;
    }
  }
}
.time-to-fade {
  animation: fading 0.6s linear 1s forwards;
}

@keyframes fading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    z-index: -9999;
    visibility: hidden;
  }
}
</style>
